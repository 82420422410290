<template>
  <div class="colNormalTableCom">
    <el-table
        v-if="showTable"
        ref="multipleTable"
        :data="tempTableData"
        stripe
        :height="tableHeight"
        :header-cell-style="headerStyle"
        :cell-style="cellStyle"
        @select-all="selectAll"
        :expand-row-keys="expandFunc"
        row-key="mkolid"
        @select="selectChange"
        size="mini"
    >

      <el-table-column
          prop="name"
          label="KOL名称"
          align="center"
          :show-overflow-tooltip="true"
          fixed
          width="150"
      >
        <template #default="{ row }">
          <div style="display: flex; align-items: center;padding-left:15px;">
            <img
                style="
                border: 1px solid #fff;
                border-radius: 50%;
                width: 28px;
                height: 28px;
              "
                :src="row.logoUrl"
                alt=""
            />
            <el-tooltip
                v-if="row.activeViewerNum === null"
                effect="dark"
                placement="top"
            >
              <template #content> KOL期间内未发布内容</template>
              <svg-icon class-name="question" class="questionClass"></svg-icon>
            </el-tooltip>

            <span class="name ellipsis">
              {{ row.kolName }}
            </span>
          </div>
        </template>
      </el-table-column>
      <!--排序列-->

      <el-table-column
          v-for="(item, index) in colData"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="setWidthByIndex(item)"
          align="center"
          :label-class-name="item.isRank && isHighLight ? 'colActClass' : ''"
          :class-name="item.isRank && isHighLight ? 'colActClass' : ''"
      >
        <!--表头-->
        <template #header>
          <div
              v-if="item.canRank"
              class="clickClass"
              :class="item.isRank && isHighLight ? 'colActClass' : ''"
              @click="sortClick(item.prop, item.label)"
          >
            <el-tooltip
                v-if="item.info !== null && item.info"
                placement="top"
                :content="item.info"
                popper-class="popper"
            >
              <span>{{ item.label }}</span>
            </el-tooltip>
            <span v-else>{{ item.label }}</span>
          </div>
        </template>
        <!--表格-->
        <template #default="{ row }">
          <span v-if="item.prop === 'postCountGrowthTrend'">
            <span v-if="row[item.prop] !== null">{{ sliceDecimal(row[item.prop])}}</span>
            <span v-else>--</span>
          </span>
          <span v-if="item.prop === 'fansGrowthTrend' || item.prop === 'engagementGrowthTrend'">
            <span v-if="row[item.prop] !== null">{{ sliceDecimal(row[item.prop] * 100) }}%</span>
            <span v-else>--</span>
          </span>
          <span v-if="item.prop === 'postCount'">
            <span v-if="row[item.prop] !== null">{{ sliceDecimal(row[item.prop])}}</span>
            <span v-else>--</span>
          </span>
          <span v-if="item.prop === 'fansNum'">
            <span v-if="row[item.prop] !== null">{{ sliceDecimal(row[item.prop])}}</span>
            <span v-else>--</span>
          </span>
          <span v-if="item.prop === 'engagementSum'">
            <span v-if="row[item.prop] !== null">{{ sliceDecimal(row[item.prop])}}</span>
            <span v-else>--</span>
          </span>
        </template>
      </el-table-column>
      <!--操作列-->
      <el-table-column
          prop="handle"
          label="操作"
          width="120"
          align="center"
      >
        <template #default="{ row }">
          <el-tooltip effect="dark" content="跳转首页" placement="top">
            <img class="icon" @click="jumpUrl(row)" src="./icon2.svg">
          </el-tooltip>
          <el-tooltip effect="dark" content="复制链接" placement="top">
            <img class="icon" @click="copyUrl(row)" src="./icon1.svg">
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        title="新增标签"
        @close="cancelAddTag"
        v-model="tagDialogVisible"
        width="30%"
    >
      <el-select
          v-model="tagValue"
          multiple
          filterable
          allow-create
          default-first-option
          @change="changeNewLabel"
          @blur="blurNewLabel"
          placeholder="请选择"
      >
        <el-option
            v-for="item in tagOptions"
            :key="item.label"
            :label="item.label"
            :value="item.label"
        >
        </el-option>
      </el-select>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelAddTag">取 消</el-button>
          <el-button type="primary" @click="addNewTag">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { delKolLabel, addNewKolLabel } from "@/api/rankingList";
import { CirclePlusFilled, Delete } from "@element-plus/icons-vue";
// import { toPercentage } from '@/utils/tools'

export default {
  name: "tableV2",
  data() {
    return {
      limit: 25,
      checkBoxList: [],
      brandListId: [],
      showTable: false,
      isInit: false,
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
        overflow: "hidden",
      },
      cellStyle: {
        fontSize: "12px",
      },
      colData: [],
      rankCol: "activeViewerNum",
      tableHeight: 600,
      rankingListV2: [
        {
          canRank: 1,
          info: null,
          isRank: 0,
          label: "总作品数量",
          prop: "postCount",
          type: "Integer",
        },{
          canRank: 1,
          color: null,
          icon: null,
          isRank: 0,
          label: "近4周新增作品数量",
          prop: "postCountGrowthTrend",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          isRank: 0,
          label: "总粉丝量",
          order_id: 4,
          prop: "fansNum",
          round: null,
          type: "Integer",
        },

        {
          canRank: 1,
          color: null,
          icon: null,
          isRank: 1,
          label: "近4周粉丝数增长率",
          order_id: 5,
          prop: "fansGrowthTrend",
          round: null,
          type: "Integer",
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          isRank: 0,
          label: "总互动量",
          order_id: 10,
          prop: "engagementSum",
          round: null,
          type: "Integer",
          formatter: (row, column, cellValue) => {
            return this.sliceDecimal(cellValue);
          },
        },
        {
          canRank: 1,
          color: null,
          icon: null,
          isRank: 0,
          label: "近4周总互动增长率",
          order_id: 11,
          prop: "engagementGrowthTrend",
          round: null,
          type: "Integer",
        },
      ],
      tempScope: {},
      tagDialogVisible: false,
      tagValue: [],
      tempTableData: [],
      isShowCoopInfo: false,
      isAddSeedscoreHead: false,
    };
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    platform: {
      type: String,
      default: "douyin",
    },
    showHandle: {
      type: Boolean,
      default: true,
    },
    benchmarkValue: {
      type: Object,
      default: () => {},
    },
    allTagOptions: {
      type: Array,
      default: () => [],
    },
    seedingScoreHeads: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      "siteValue",
      "colSiteValue",
      "isHighLight",
      "isExpand",
      "menuList",
    ]),
    expandFunc() {
      if (!this.isExpand) {
        return [];
      } else {
        const result = this.tableData.map((v) => {
          return v.mkolid;
        });
        return result;
      }
    },
    site() {
      return this.$route.name === "RankingListV2"
          ? this.siteValue
          : this.colSiteValue;
    },
    priceInfo() {
      switch (this.site) {
        case "wechat":
          return ["一推", "二推"];
        case "weibo":
          return ["直发", "转发"];
        case "douyin":
          return ["视频21-60s", "视频1-20s"];
        case "xiaohongshu":
          return ["图文笔记", "视频笔记"];
        case "bilibili":
          return ["视频定制", "视频植入"];
        case "kuaishou":
          return ["原创视频"];
        default:
          return ["默认值"];
      }
    },
    tagOptions() {
      if (this.tempScope.label) {
        let res = this.allTagOptions;
        this.tempScope.label.forEach((tag) => {
          res = res.filter((v) => v.label !== tag);
        });
        return res;
      } else {
        return this.allTagOptions;
      }
    },
  },
  mounted() {
    this.tableHeight = document.body.clientHeight - 300;
    this.sortClick("fansNum", '总粉丝量', true);
    // this.$refs.multipleTable.doLayout();
  },
  methods: {
    ...mapMutations({
      setIsHighLight: "SET_ISHIGHLIGHT",
    }),
    setBorderColorStyle(index, scope) {
      if (index === scope.price.length - 1) {
        switch (scope.price[index].color) {
          case "pred":
            return "noBorder yellowText";
          case "crawl":
            return "noBorder greenText";
          case "campaign":
            return "noBorder blueText";
          case "custom":
            return "noBorder pinkText";
          default:
            return "noBorder";
        }
      } else {
        switch (scope.price[index].color) {
          case "pred":
            return "haveBorder yellowText";
          case "crawl":
            return "haveBorder greenText";
          case "campaign":
            return "haveBorder blueText";
          case "custom":
            return "haveBorder pinkText";
          default:
            return "haveBorder";
        }
      }
    },
    setBorderStyle(index, len) {
      if (index === len - 1) {
        return "noBorder";
      } else {
        return "haveBorder";
      }
    },
    buildExpandTableData(v) {
      return [
        {
          adProportion: v.adProportion ? v.adProportion : "0%",
          coopBrand: v.coopBrand ? v.coopBrand.split(",") : ["--"],
          price:
              this.siteValue === "kuaishou"
                  ? [
                    {
                      price:
                          v.price1 !== null
                              ? `¥ ${this.sliceDecimal(v.price1)}`
                              : "--",
                      color: v.priceSource1,
                    },
                  ]
                  : [
                    {
                      price:
                          v.price1 !== null
                              ? `¥ ${this.sliceDecimal(v.price1)}`
                              : "--",
                      color: v.priceSource1,
                    },
                    {
                      price:
                          v.price2 !== null
                              ? `¥ ${this.sliceDecimal(v.price2)}`
                              : "--",
                      color: v.priceSource2,
                    },
                  ],
          er:
              this.siteValue === "kuaishou"
                  ? [v.er1 || "--"]
                  : [v.er1 || "--", v.er2 || "--"],
          cpuv:
              this.siteValue === "kuaishou"
                  ? [v.cpuv1 !== null ? `¥${this.sliceDecimal(v.cpuv1)}` : "--"]
                  : [
                    v.cpuv1 !== null ? `¥${this.sliceDecimal(v.cpuv1)}` : "--",
                    v.cpuv2 !== null ? `¥${this.sliceDecimal(v.cpuv2)}` : "--",
                  ],
          postRead:
              this.siteValue === "kuaishou"
                  ? [v.postRead1 !== null ? this.sliceDecimal(v.postRead1) : "--"]
                  : [
                    v.postRead1 !== null ? this.sliceDecimal(v.postRead1) : "--",
                    v.postRead2 !== null ? this.sliceDecimal(v.postRead2) : "--",
                  ],
          taPostRead:
              this.siteValue === "kuaishou"
                  ? [
                    v.taPostRead1 !== null
                        ? this.sliceDecimal(v.taPostRead1)
                        : "--",
                  ]
                  : [
                    v.taPostRead1 !== null
                        ? this.sliceDecimal(v.taPostRead1)
                        : "--",
                    v.taPostRead2 !== null
                        ? this.sliceDecimal(v.taPostRead2)
                        : "--",
                  ],
          postEngagement:
              this.siteValue === "kuaishou"
                  ? [
                    v.postEngagement1 !== null
                        ? this.sliceDecimal(v.postEngagement1)
                        : "--",
                  ]
                  : [
                    v.postEngagement1 !== null
                        ? this.sliceDecimal(v.postEngagement1)
                        : "--",
                    v.postEngagement2 !== null
                        ? this.sliceDecimal(v.postEngagement2)
                        : "--",
                  ],
          cpe:
              this.siteValue === "kuaishou"
                  ? [v.cpe1 ? "¥" + v.cpe1 : "--"]
                  : [v.cpe1 ? "¥" + v.cpe1 : "--", v.cpe2 ? "¥" + v.cpe2 : "--"],
          cpta:
              this.siteValue === "kuaishou"
                  ? [v.cpta1 ? "¥" + v.cpta1 : "--"]
                  : [
                    v.cpta1 ? "¥" + v.cpta1 : "--",
                    v.cpta2 ? "¥" + v.cpta2 : "--",
                  ],
          priceInfo: this.priceInfo,
        },
      ];
    },
    buildHistoryInfoTableData(v) {
      let temparr = [];
      temparr = [
        {
          coopCostAvg: v.coopCostAvg
              ? "¥" + this.sliceDecimal(v.coopCostAvg)
              : "--",
          coopCpuv: v.coopCpuv ? "¥" + this.sliceDecimal(v.coopCpuv) : "--",
          coopCpe: v.coopCpe ? "¥" + v.coopCpe : "--",
          coopCount: v.coopCount ? v.coopCount : "--",
          coopCampaignType: v.coopCampaignType ? v.coopCampaignType : "--",
        },
      ];
      this.historyInfoTableData = temparr;
      return temparr;
    },
    buildCoopInfoData(v) {
      return v.kolLatestCoopInfoList;
    },
    // 能删除某个label表示label有数据
    // 删除某个label
    async deleteLabel(tag, scope) {
      // 删除label
      let { code } = await delKolLabel({
        labelName: tag,
        mKolid: scope.mKolid,
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "删除标签成功!",
        });
        this.$emit("reRenderTagShow");
      } else {
        this.$message.error("删除标签失败!");
      }
    },
    addNewLabel(scope) {
      this.tagValue = [];
      this.tempScope = scope;
      this.tagDialogVisible = true;
    },
    changeNewLabel(val) {
      // 可多选  可输入  可筛选
      // 输入为空时不生成tag
      this.tagValue = val.map((item) => item.trim());
      this.tagValue = this.tagValue.filter((item) => item.trim() !== "");
    },
    blurNewLabel(e) {
      const inputVal = e.target.value.trim();
      if (inputVal) {
        this.tagValue.push(inputVal);
      }
    },
    cancelAddTag() {
      this.tagValue = [];
      this.tempScope = {};
      this.tagDialogVisible = false;
    },
    async addNewTag() {
      // 判断新增标签已存不存在
      if (this.tempScope.label) {
        for (let i = 0; i < this.tagValue.length; i++) {
          if (this.tempScope.label.includes(this.tagValue[i])) {
            this.$message({
              type: "info",
              message: "新增标签已经存在，请重新填写!",
            });
            return;
          }
        }
      }
      let labelNames = this.tempScope.label
          ? this.tempScope.label.concat(this.tagValue)
          : this.tagValue;
      let { code } = await addNewKolLabel({
        labelNames,
        mKolid: this.tempScope.mKolid,
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "新增标签成功!",
        });
        // 触发更新，重新渲染tagShow 组件
        this.$emit("reRenderTagShow");
      } else {
        this.$message.error("新增标签失败!");
      }
      this.cancelAddTag();
    },
    setWidthByIndex(item) {
      if (item.label === "TA TGI") {
        return 55;
      } else if (item.label === "类型" || item.label === "级别") {
        return 38;
      } else if (item.label === "周作品数量") {
        return 70;
      } else if (
          item.prop === "seedingScore1" ||
          item.prop === "seedingScore2"
      ) {
        return 135;
      } else {
        return null;
      }
    },
    sortClick(value, label) {
      if (!this.isHighLight) {
        this.setIsHighLight(true);
      }
      this.rankCol = value;
      this.choseColData();
      this.$emit("changeSort", value, !this.isInit?'总粉丝量':label, false, );
      this.isInit = true;
    },
    nameClick(row) {
      // 改为弹窗模式，就向上广播
      this.$emit("openDetail", row.mkolid, row.name);
    },
    selectChange(item, row) {
      this.$emit(
          "checkEmpty",
          item.some((v) => {
            return (
                v.activeViewerNum === 0 ||
                v.activeViewerNum === undefined ||
                v.activeViewerNum === null
            );
          })
      );

      // if (item.length > this.limit) {
      // this.$refs.multipleTable.toggleRowSelection(row);
      // this.checkBoxList = item.slice(0, this.limit);
      // this.checkboxValueChange(true);
      this.brandListId = item.map((v) => v.mkolid);
      // }
      // else {
      this.checkBoxList = item;
      this.checkboxValueChange();
      // }
    },
    truncateString(s) {
      if (s.length > 4) {
        return s.slice(0, 3) + "...";
      } else {
        return s;
      }
    },
    selectAll(item) {
      this.$emit(
          "checkEmpty",
          item.some((v) => {
            return (
                v.activeViewerNum === 0 ||
                v.activeViewerNum === undefined ||
                v.activeViewerNum === null
            );
          })
      );
      this.$refs.multipleTable.clearSelection();
      // if (this.checkBoxList.length === this.limit) {
      //   this.checkBoxList = [];
      // } else {

      // this.checkBoxList = item;
      this.checkBoxList = item.slice(0, this.limit);
      this.brandListId = this.checkBoxList.map((v) => v.mkolid);

      this.checkBoxList.forEach((item) => {
        this.$refs.multipleTable.toggleRowSelection(item, true);
      });
      // }
      this.checkboxValueChange();
    },
    checkboxValueChange(bool = false) {
      if (bool) {
        this.$emit("checkboxValueChange", this.checkBoxList, bool);
      } else {
        this.$emit("checkboxValueChange", this.checkBoxList);
      }
    },
    choseColData() {
      this.rankingListV2.forEach((v) => {
        v.isRank = 0;
        if (v.prop === this.rankCol) {
          v.isRank = 1;
        }
      });
      this.colData = this.rankingListV2;
    },

    openDelKolDialog(id) {
      this.$emit("openDelKolDialog", id);
    },
    rowClass({ column, rowIndex, columnIndex }) {
      const tableHeaderStyle = {
        height: "35px",
        lineHeight: "35px",
      };
      if (rowIndex === 0 && columnIndex === 0) {
        this.$nextTick(() => {
          document
              .getElementsByClassName(column.id)[0]
              .setAttribute("colSpan", 2);
        }); //  一定要写在加载完毕后，nextTick 更新的最晚，才能获取到dom节点。
      }
      if (rowIndex === 0 && columnIndex === 1) {
        return { display: "none" };
      }
      return tableHeaderStyle;
    },
    disabledSelect(row) {
      if (this.brandListId.includes(row.mkolid)) {
        return true;
      } else if (this.brandListId.length >= this.limit) {
        return false;
      } else {
        return true;
      }
    },
    jumpUrl(row){
      window.open(row.platformHomeLinkUrl)
    },
    copyUrl(row){
      navigator.clipboard.writeText(row.platformHomeLinkUrl)
          .then(() => {
            this.$message({
              message: "地址复制成功！",
              type: "success",
            });
          })
          .catch(err => {
            console.error('Error in copying text: ', err);
          });
    }
    // rowClassCoop() {
    //   return {
    //     height: "35px",
    //     lineHeight: "35px",
    //   };
    // },
  },
  watch: {
    menuList: {
      handler(val) {
        if (val) {
          let tempArr = val.find((v) => v.id === 410);
          if (tempArr) {
            let isShowCoopInfo = tempArr.children.find((v) => v.id === 430);
            if (isShowCoopInfo) {
              this.isShowCoopInfo = true;
            } else {
              this.isShowCoopInfo = false;
            }
          }
        }
      },
      immediate: true,
      deep: true,
    },
    seedingScoreHeads() {
      if (!this.isAddSeedscoreHead && this.seedingScoreHeads.length !== 0) {
        this.isAddSeedscoreHead = true;
        this.rankingListV2.push(...this.seedingScoreHeads);
      }
    },
    tableData(newVal) {
      this.showTable = false;
      // 将label从string改为数组
      newVal.forEach((v) => {
        if (v.label && typeof v.label === "string") {
          v.label = v.label.split("|");
        }
      });
      this.choseColData();
      setTimeout(() => {
        this.showTable = true;
      }, 20);
      const len = this.checkBoxList.length;
      if (len) {
        setTimeout(() => {
          for (let item of this.checkBoxList) {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          }
        }, 50);
      }
      setTimeout(() => {
        this.$refs.multipleTable.doLayout();
      }, 100);
      this.tempTableData = newVal.map((item) => item);
    },
    site: {
      handler(val) {
        this.rankCol = "activeViewerNum";
        this.choseColData();
      },
      immediate: true,
    },
  },
  components: {
    CirclePlusFilled,
    Delete
  },
};
</script>

<style lang="scss">
.colNormalTableCom {
  // overflow: auto;
  .expandWrapper table td,
  .expandWrapper table th {
    padding: 5px;
  }

  // .el-table__fixed-body-wrapper {
  //   top: 68px !important;
  // }

  .tagWrapDiv .hoverI {
    &:hover {
      color: #409eff;
    }
  }

  .el-tag + .el-tag {
    margin-left: 10px;
  }

  .el-table__cell.el-table__expanded-cell {
    padding: 10px 40px;
  }

  .iconStyle {
    font-size: 12px;
    cursor: pointer;
    position: relative;
    top: -1px;
  }
}
</style>

<style scoped lang="scss">
.priceSpan {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.haveBorder {
  border-bottom: 1px solid #ebeef5;
}

.noBorder {
  border-bottom: none;
}

.green {
  background-color: #67c23a;
}

.yellow {
  background-color: #e6a23c;
}

.blue {
  background-color: #53bad2;
}

.pink {
  background-color: #f37092;
}

.priceGreen {
  color: #67c23a;
}

.priceYellow {
  color: #e6a23c;
}

.yellowText {
  color: #e6a23c;
}

.pinkText {
  color: #f37092;
}

.blueText {
  color: #51a6f0;
}

.greenText {
  color: #67c23a;
}

.name {
  //cursor: pointer;
  margin-left: 6px;

  &:hover {
    //color: orange;
  }
}

.questionClass {
  position: absolute;
  right: 0;
  top: 10px;
}

.tier-box {
  width: 800px;
  img {
    width: 100%;
    height: 100%;
  }
}
.icon{
  width:32px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
