<template>
  <div class="tagShowDiv">
    <!-- 分类 -->
    <!--    <tag-item-->
    <!--        :data="platformClassifyData"-->
    <!--        classify="分类"-->
    <!--        :reset="modelValue"-->
    <!--        v-model="tags.categories"-->
    <!--    />-->
    <!--    &lt;!&ndash; 级别，榜单1.0没有级别 &ndash;&gt;-->
    <!--    <tag-item-->
    <!--        v-show="listVersion"-->
    <!--        :data="tierData"-->
    <!--        classify="级别"-->
    <!--        :reset="modelValue"-->
    <!--        @tireChange="tireChange"-->
    <!--        v-model="tags.tiers"-->
    <!--    />-->
    <!-- 标签 榜单1.0没有标签-->
    <!-- <tag-item
      v-show="listVersion"
      :data="labelData"
      classify="标签"
      :reset="modelValue"
      v-model="tags.labels"
    /> -->
    <!-- 其他筛选 -->
    <!--    <div class="otherFilterDiv" v-show="otherFilterList.length">-->
    <!--      <p class="title">其他筛选</p>-->
    <!--      <span @click="showAllFilter" class="spanExpandAll">{{-->
    <!--          isShowAllFilter ? "收起前测筛选" : "展开所有前测筛选"-->
    <!--        }}</span>-->
    <!--      <filterItem-->
    <!--          v-for="(item, index) in otherFilterList"-->
    <!--          :key="'otherFilter' + index"-->
    <!--          :data="item"-->
    <!--          @handleValue="filterObjSet"-->
    <!--          @deleteFilter="deleteFilterData"-->
    <!--          :resetFlag="resetFilterItem"-->
    <!--          :clearOneData="clearOneData"-->
    <!--      >-->
    <!--      </filterItem>-->

    <!--      &lt;!&ndash; <div v-if="isShowAllFilter">-->
    <!--        <span class="lightSpan">作品高水量Alert</span>-->
    <!--        <tag-item-->
    <!--          v-show="listVersion"-->
    <!--          :isLight="true"-->
    <!--          :data="LightData"-->
    <!--          classify="作品高水量Alert"-->
    <!--          :reset="modelValue"-->
    <!--          v-model="tags.waterAlert"-->
    <!--        />-->
    <!--      </div> &ndash;&gt;-->
    <!--    </div>-->
    <!-- 数据筛选 -->
    <!-- <div>
      <p
        style="
          border-bottom: 1px solid #dcdfe6;
          margin: 12px 0 10px;
          font-weight: bold;
          height: 19px;
          font-size: 14px;
        "
      >
        数据筛选
      </p>
      <filterItem
        v-for="(item, index) in dataFilterList"
        :key="'dataFilter' + index"
        :data="item"
        @handleValue="filterObjSet"
        @deleteFilter="deleteFilterData"
        :resetFlag="resetFilterItem"
        :clearInput="clearInput"
      >
      </filterItem>
    </div> -->

    <!-- 八大人群筛选 -->
    <div class="otherFilterDiv" >
      <p class="title">筛选</p>
      <filterItem
          v-for="(item, index) in eightGroups"
          :key="'eightGroups' + index"
          :data="item"
          @handleValue="filterObjSet"
          @deleteFilter="deleteFilterData"
          :resetFlag="resetFilterItem"
          :clearOneData="clearOneData"
      >
      </filterItem>
    </div>
    <!-- 按钮组 （确定/重置）-->
    <div
        class="btnDiv"
        style="text-align: right; margin-right: 30px; margin-top: 10px"
    >
      <el-button
          size="mini"
          type="primary"
          style="vertical-align: top"
          @click="submitData"
      >
        确定
      </el-button>
      <el-button
          size="mini"
          type="primary"
          style="vertical-align: top"
          @click="resetClick"
      >
        重置
      </el-button>
    </div>
  </div>
</template>

<script>
import tagItem from "./tagItem";
import filterItem from "./filterItem";
// import { getCampaignType } from "@/api/campaign";
// import { getCategoryTree } from "@/api/rankingList";
import { mapGetters } from "vuex";

// 输入都是自定义筛选，然后动态修改tier的值。但是重复请求怎么办？

export default {
  name: "tag-show",
  data() {
    return {
      tags: {
        categories: ["All"], // 标签
        tiers: ["All"], // tier，榜单1.0没有tier
        labels: ["All"],
        waterAlert: ["All"],
        // filter: {}, // 其他筛选,
      },
      isShowAllFilter: false,
      filterObj: {},
      filterTags: [],
      resetFilterItem: false,
      clearOneData: "",
      clearInput: false,
      isInit: true,
      // 固定数据
      eightGroups: [
        {
          label: "总作品数量",
          value: "postCount",
          showPercent: false,
          isDeleteData: false,
        },
        {
          label: "近4周新增作品数量",
          value: "postCountGrowthTrend",
          showPercent: false,
          isDeleteData: false,
        },
        {
          label: "总粉丝数量",
          value: "fansNum",
          showPercent: false,
          isDeleteData: false,
        },
        {
          label: "近4周粉丝数增长率",
          value: "fansGrowthTrend",
          showPercent: true,
          isDeleteData: false,
        },
        {
          label: "总互动量",
          value: "engagementSum",
          showPercent: false,
          isDeleteData: false,
        },


        {
          label: "近4周总互动量数增长率",
          value: "engagementGrowthTrend",
          showPercent: true,
          isDeleteData: false,
        }
      ],
      coopBrand: "",
      coopCampaignType: [],
      isSetMyTier: false,
      // coopTypeOptions: [],
    };
  },
  props: {
    labelData: {
      type: Array,
      default() {
        return [];
      },
    },
    // 自定义tier
    myTier: {
      type: Array,
      default() {
        return ["All"];
      },
    },
    // 自定义tag
    myTag: {
      type: Object,
      default() {
        return {};
      },
    },
    // v-model 清空数据
    modelValue: {
      type: Boolean,
      default: false,
    },
    isShowCoopInfoFilter: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters(["siteValue", "colSiteValue", "listVersion", "classifyData"]),
    chosenSite() {
      return this.$route.name === "RankingListV2"
          ? this.siteValue
          : this.colSiteValue;
    },
    platformClassifyData() {
      return this.classifyData[this.chosenSite];
    },
    tierData() {
      if (this.listVersion === 1 && this.$route.name !== "CampaignInfo") {
        if (this.siteValue === "wechat" || this.colSiteValue === "wechat") {
          return [
            {
              name: "超头",
              value: 1,
              tip: "",
            },
            {
              name: "头部",
              value: 2,
              tip: "",
            },
            {
              name: "腰部",
              value: 3,
              tip: "",
            },
            {
              name: "尾部",
              value: 4,
              tip: "",
            },
          ];
        } else {
          return [
            {
              name: "超头",
              value: 1,
              tip: "",
            },
            {
              name: "头部",
              value: 2,
              tip: "",
            },
            {
              name: "腰部",
              value: 3,
              tip: "",
            },
            {
              name: "尾部",
              value: 4,
              tip: "",
            },
            {
              name: "KOC",
              value: 5,
              tip: "",
            },
            {
              name: "UGC",
              value: 0,
              tip: "",
            },
          ];
        }
      } else {
        return [];
      }
    },
    LightData() {
      if (this.listVersion === 1 && this.$route.name !== "CampaignInfo") {
        return [
          {
            name: "绿灯",
            tip: "",
          },
          {
            name: "红灯",
            tip: "",
          },
        ];
      } else {
        return [];
      }
    },
    otherFilterList() {
      if (this.listVersion === 1 && this.$route.name !== "CampaignInfo") {
        return this.v2OtherFilter;
      } else if (this.$route.name !== "CampaignInfo") {
        const otherFilter = this.chosenSite + "OtherFilter";
        return this[otherFilter];
      } else {
        return [];
      }
    },
    weiboDataFilter() {
      return this.listVersion === 1
          ? [
            {
              label: "ER",
              value: "engagementRate",
              showPercent: true,
            },
            {
              label: "CPUV（直发）",
              value: "cpuv",
              showPercent: false,
            },
            {
              label: "CPUV（转发）",
              value: "cpuvSecond",
              showPercent: false,
            },
            {
              label: "CPE（直发）",
              value: "cpe",
              showPercent: false,
            },
            {
              label: "CPE（转发）",
              value: "cpeSecond",
              showPercent: false,
            },
            {
              label: "价格（直发）",
              value: "price",
              showPercent: false,
            },
            {
              label: "价格（转发）",
              value: "priceSecond",
              showPercent: false,
            },
            {
              label: "CPTA（直发）",
              value: "cpta",
              showPercent: false,
            },
            {
              label: "CPTA（转发）",
              value: "cptaSecond",
              showPercent: false,
            },
          ]
          : [
            {
              label: "价格（直发）",
              value: "price",
              showPercent: false,
            },
            {
              label: "价格（转发）",
              value: "priceSecond",
              showPercent: false,
            },
            {
              label: "CPE（直发）",
              value: "cpe",
              showPercent: false,
            },
            {
              label: "CPE（转发）",
              value: "cpeSecond",
              showPercent: false,
            },
          ];
    },
    infoDataFilterList() {
      return [
        {
          label: "近3月合作品牌数",
          value: "coopCount",
          showPercent: false,
        },
      ];
    },
    dataFilterList() {
      const dataFilter =
          this.$route.name === "CampaignInfo"
              ? "campaignInfoDataFilter"
              : this.chosenSite + "DataFilter";
      return this[dataFilter];
    },
  },
  mounted() {
    this.submitData();
    setTimeout(() => {
      this.isInit = false;
    }, 400);
  },
  methods: {
    // async getCategoryTreeFunc() {
    //   let res = await getCategoryTree();
    //   if (res.code === 0) {
    //     this.coopTypeOptions = res.data;
    //   }
    // },
    showAllFilter() {
      this.filterObj = {};
      this.filterTags = [];
      this.isShowAllFilter = !this.isShowAllFilter;
      this.resetFilterItem = true;
      setTimeout(() => {
        this.resetFilterItem = false;
      }, 200);
      if (this.isShowAllFilter) {
        if(this.$route.name === "RankingListV2"){
          this.v2OtherFilter = [
            {
              label: "粉丝数",
              value: "fansNum",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "周作品数量",
              value: "postCount",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "单条阅读UV",
              value: "postRead",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "单条互动",
              value: "postEngagement",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "作品水量指数",
              value: "waterIndex",
              showPercent: false,
              isDeleteData: false,
            },
            // {
            //   label: "TA浓度%",
            //   value: "taRatio",
            //   showPercent: true,
            //   isDeleteData: false,
            // },
            // {
            //   label: "TA TGI",
            //   value: "taTgi",
            //   showPercent: false,
            //   isDeleteData: false,
            // },
            {
              label: "CPUV",
              value: "cpuv",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "CPE",
              value: "cpe",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "CPTA",
              value: "cpta",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "Seeding Score",
              value: "score",
              showPercent: false,
              isDeleteData: false,
            },
          ];
        } else{
          this.v2OtherFilter = [
            {
              label: "粉丝数",
              value: "fansNum",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "周作品数量",
              value: "postCount",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "单条阅读UV",
              value: "postRead",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "单条互动",
              value: "postEngagement",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "作品水量指数",
              value: "waterIndex",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "TA浓度%",
              value: "taRatio",
              showPercent: true,
              isDeleteData: false,
            },
            {
              label: "TA TGI",
              value: "taTgi",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "CPUV",
              value: "cpuv",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "CPE",
              value: "cpe",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "CPTA",
              value: "cpta",
              showPercent: false,
              isDeleteData: false,
            },
            {
              label: "Seeding Score",
              value: "score",
              showPercent: false,
              isDeleteData: false,
            },
          ];
        }

      } else {
        this.v2OtherFilter = [
          {
            label: "粉丝数",
            value: "kolFans",
            showPercent: false,
            isDeleteData: false,
          },
          {
            label: "CPE",
            value: "cpe",
            showPercent: false,
            isDeleteData: false,
          },
          {
            label: "CPTA",
            value: "cpta",
            showPercent: false,
            isDeleteData: false,
          },
          {
            label: "Seeding Score",
            value: "postEngagement",
            showPercent: false,
            isDeleteData: false,
          },
        ];
      }
      this.submitData();
    },
    delCategoryData() {
      this.tags.categories = ["All"];
    },
    delTierData() {
      this.tags.tiers = ["All"];
    },
    delLabelData() {
      this.tags.labels = ["All"];
    },
    delCoopBrand() {
      this.coopBrand = "";
      this.submitData();
    },
    delCampaignTypes() {
      this.coopCampaignType = [];
      this.submitData();
    },
    delLightTypes() {
      this.tags.waterAlert = ["All"];
      this.submitData();
    },
    deleteFilterData(filterKeys) {
      this.isClearData(filterKeys);
      const i = this.filterTags.findIndex(
          (item) => item.filterKeys[0] === filterKeys[0]
      );
      this.filterTags.splice(i, 1);
      // 对应filterObj也的删除
      filterKeys.forEach((k) => delete this.filterObj[k]);
    },
    isClearData(item) {
      this.clearOneData = `${item[1].replace("Above", "")}-${
          Math.random(0, 1) * Math.random(0.1)
      }`;
      // if(item.value === )
    },
    // 其他筛选 数据筛选
    filterObjSet({ label, value, filterKeys }) {
      Object.assign(this.filterObj, value);
      // 数据去重
      const i = this.filterTags.findIndex(
          (item) => item.filterKeys[0] === filterKeys[0]
      );
      i === -1
          ? this.filterTags.push({ label, filterKeys })
          : this.filterTags.splice(i, 1, { label, filterKeys });
    },
    // 确定  只针对其他筛选 数据筛选
    submitData() {
      let coopObj = {
        coopBrand: this.coopBrand,
        coopCampaignType: this.coopCampaignType,
      };
      const filter = Object.assign({}, this.filterObj);
      const tags = Object.assign({ filter }, this.tags);
      this.$emit("handleCoop", coopObj);
      this.$emit("handleDataFilterTag", [...this.filterTags]);
      setTimeout(() => {
        this.$emit("handleFilter", tags);
      }, 500);
    },
    tireChange(val) {
      this.tags.tierCodeList = val;
    },
    setTier(tier) {
      this.tags.tiers = tier;
    },
    // 重置
    resetClick() {
      // tags变化会自动调用submitData
      this.tags = {
        categories: ["All"], // 标签
        tiers: ["All"], // tier，榜单1.0没有tier
        labels: ["All"],
        waterAlert: ["All"],
        // filter: {}, // 其他筛选,
      };
      this.coopBrand = "";
      this.coopCampaignType = [];
      this.filterObj = {};
      this.filterTags = [];
      this.resetFilterItem = true;
      setTimeout(() => {
        this.resetFilterItem = false;
      }, 200);
    },
  },
  watch: {
    myTier: {
      handler(newVal) {
        if (newVal) {
          this.isSetMyTier = true;
          this.tags.tiers = newVal.length === 0 ? ["All"] : newVal;
        }
      },
      deep: true,
    },
    myTag: {
      handler(newVal) {
        if (newVal) {
          // this.tags = newVal;
        }
      },
      deep: true,
    },
    // 清空数据
    modelValue(newVal) {
      if (newVal) {
        this.resetClick();
        setTimeout(() => {
          this.$emit("update:modelValue", false);
        }, 200);
      }
    },
    platformClassifyData: {
      handler() {
        this.tags.categories = ["All"];
      },
      deep: true,
    },
    tierData: {
      handler() {
        this.tags.tiers = ["All"];
      },
      deep: true,
    },
    labelData: {
      handler() {
        if (this.isSetMyTier) {
          this.isSetMyTier = false;
        }
        this.tags.labels = ["All"];
      },
      deep: true,
    },
    tags: {
      handler(val) {
        if (this.isSetMyTier) {
          this.isSetMyTier = false;
          return;
        } else {
          if (!this.isInit) {
            this.submitData();
          }
        }
      },
      deep: true,
    },
  },
  components: {
    tagItem,
    filterItem,
  },
};
</script>

<style scoped lang="scss">
.tagShowDiv {
  border: 1px solid #dcdfe6;
  padding: 0 15px 5px;
}
.otherFilterDiv {
  position: relative;
  .spanExpandAll {
    position: absolute;
    right: 0;
    top: -2px;
    color: #409eff;
    cursor: pointer;
  }
}
.lightSpan {
  display: inline-block;
  width: 130px;
  color: rgb(136, 136, 136);
  margin-right: 10px;
}
.spanList {
  margin-right: 15px;
  cursor: pointer;
}
.green {
  color: #67c23a;
}
.red {
  color: #ff0000;
}
.all {
  border: 1px solid rgba(64, 158, 255, 0.2);
  background-color: rgba(64, 158, 255, 0.1);
  color: #409eff;
  padding: 0 8px;
  line-height: 24px;
}

.title {
  border-bottom: 1px solid #dcdfe6;
  margin: 12px 0 10px;
  font-weight: bold;
  height: 19px;
  font-size: 14px;
}
</style>

<style lang="scss">
.coopForm .el-form-item__label {
  color: rgb(136, 136, 136);
}
</style>
