<template>
  <div class="container">
    <sidebar style="margin-right: 20px" v-if="showSideBar" />
    <div
        style="
        flex: 1;
        background-color: #fff;
        /*display: flex;*/
        flex-direction: column;
        overflow-y: hidden;
        border-radius: 10px;
      "
    >
      <filter-com
          :showListVersion="showListVersion"
          :colEmpty="colEmpty"
          :duration="duration"
          :collectionId="collectionId"
          @addToActivity="addToActivity"
          @changeSort="sortClick"
      />
      <div
          style="
          flex: 1;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          padding: 5px 10px 10px;
        "
      >
        <div>
          <tool-bar>
            <template #select>
              <new-select-com
                  v-model="tagshow"
                  :tagshowNumber="filterTags.length"
                  :filterId="filterId"
                  @changeInput="query = $event"
                  @handleSearch="newSearch"
                  @changeFilter="changeFilter"
                  @resetData="resetFlag = $event"
                  :tagshow="tagshow"
                  @changeTagShow="tagshow = $event"
              />
            </template>
          </tool-bar>
          <filter-tag
              ref="filterTag"
              v-show="tagshow"
              v-model="resetFlag"
              :labelData="labelData"
              :myTier="myTier"
              @handleFilter="newSearch"
              @handleDataFilterTag="filterTagArr = $event"
          />
          <div class="tagDiv">
            <el-tag
                closable
                class="ellipsis"
                @close="tagClose(tag)"
                v-for="tag in filterTags"
                :key="tag.name"
                :type="tag.type"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </div>
        <div
            class="dataDiv"
            v-loading.lock="dataLoading"
            :style="size === 'mini' ? { padding: '5px 0' } : {}"
        >
          <table-component
              v-if="listVersion"
              :tableData="rankListTableData"
              :benchmarkValue="benchmarkValue"
              @checkEmpty="checkEmpty"
              ref="normalTable"
              @checkboxValueChange="checkboxValueChange"
              @changeSort="sortClick"
              @openDetail="openDetail"
              @reRenderTagShow="reRenderTagShow"
              :allTagOptions="allTagOptions"
              :showHandle="showHandle"
              @openDelKolDialog="openDelKolDialog"
              :isShowCoopInfo="isShowCoopInfo"
              :platform="platform"
              :empty="setEmpty"
              :duration="duration"
          />
          <!--分页器-->
          <div class="pagination ">
            <span class="dataTime">
              * 统计数据时间为：{{
                month.slice(0, 4) + "-" + month.slice(4)
              }}
            </span>
            <el-pagination
                :current-page="listCurrentPage"
                :page-size="listPageSize"
                :total="listTotalSize"
                @current-change="handleCurrentChange"
                :page-sizes="[20, 50, 100]"
                @size-change="handleSizeChange"
                layout="prev, pager, next, jumper, sizes, total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!--错误提示-->
    <error-dialog
        v-model="errorDialogVisible"
        @confirmClick="errorDialogVisible = false"
    >
      <p>{{ errorDialogText }}</p>
    </error-dialog>
    <!--详情弹窗-->
    <el-dialog
        v-model="dialogVisible"
        fullscreen
        :show-close="false"
        destroy-on-close
    >
      <el-container class="dialog_container">
        <el-header height="90px">
          <header-com :key="key"></header-com>
        </el-header>
        <el-main>
          <kolDetails
              ref="kolDetails"
              :kolMkolId="mKolId"
              :platform="platform"
          ></kolDetails>
        </el-main>
        <!-- <el-footer height="30px">
                  <common-footer />
                </el-footer> -->
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import Sidebar from "@/components/common/socialPlatform";
import filterCom from "@/components/rankingList/filter/amateurRankingFilter.vue";
import toolBar from "@/components/toolBar";
import newSelectCom from "@/components/rankingList/colNewSelectCom";
import filterTag from "@/components/filterTag/amateurRankingTag.vue";
import touchAnalysis from "@/components/touchAnalysis/touchAnalysis";
import tableCom from "@/components/tableCom";
import errorDialog from "@/components/errorDialog";
import tableComponent from "@/components/list/amateurRankingsTable";
import kolDetails from "@/views/kolDetails";
import headerCom from "@/views/Home/header/header";
// import CommonFooter from "@/components/common/footer/footer";
import uploadFile from "@/components/uploadFile";

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {
  touchAnalysisInitData,
  getBenchmark,
  getKolList,
  getkolAverageDataList,
  personalAnalysis,
  kolTouchAnalysis,
  getKolLabel,
  downloadKolMedian,
  delAllKol,
  getInfo
} from "@/api/rankingList";
import {
  getCollectionInfo,
  getUserCollect,
  submitKol,
  creatCollect,
  getAmount,
  delHaveKol,
  getHaveKol,
  getHaveKolV2,
  downloadKolLabelTemplate,
  downloadPriceTemplate,
  submitFileUpload,
  submitPriceUpload,
} from "@/api/collection";

import { nextTick } from "vue";
import {SET_AMATEURSITES} from "../../store/mutation-types";

export default {
  name: "RankingListV2",
  data() {
    let checkColName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入收藏夹名称"));
      }
      const regular = new RegExp(
          "[`·~!@#$^&*()=+|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】 ‘；：”“'。，、？]"
      );
      if (regular.test(value) || value.replace(/\s+/g, "") === "") {
        callback(new Error("任务名称只能包含数字、字母、中文汉字、_、-"));
      } else {
        this.form.name = value.trim();
        callback();
      }
    };
    return {
      firstInit: false,
      rankListTableData: [],
      checkboxValue: [],
      isDataEmpty: false,
      listCurrentPage: 1,
      listPageSize: 50,
      listTotalSize: 0,
      dataLoading: true,
      indexNodata: false,

      // 添加到常用达人
      addToCollection: false,
      taskPageSize: 5,
      taskCurrentPage: 1,
      taskTotal: 0,
      addToColData: [],
      colTotal: 0,
      collected: 0,
      addDisAble: true,
      currentRowId: 0,
      innerVisible: false,

      sort: 0,
      sortColumn: "activeViewerNum",
      loading: true,
      noData: false,

      touchAnalysisDialogVisible: false,
      touchAnalysisData: {},

      errorDialogVisible: false,
      errorDialogText: "",

      compareDialogVisible: false,
      compareData: {},
      tags: {
        categories: [], // 标签
        tiers: [], // tier，榜单1.0没有tier
        labels: [],
        filter: {}, // 其他筛选,
        // waterAlert: ["All"],
      },
      // 其他筛选 数据筛选的一维数组集合
      filterTagArr: [],
      tagshow: false,
      query: "",
      resetFlag: false,

      form: {
        name: "",
        desc: "",
      },
      colRules: {
        name: [
          { required: true, validator: checkColName, trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        desc: [{ max: 500, message: "长度小于500字符", trigger: "blur" }],
      },

      mKolId: "",

      // 记录选中的kol
      checkedKolList: [],

      // durationValue: "",
      benchmarkValue: {},
      kolIndexs: [],
      // 删除kol
      delKolDialogVisible: false,
      delKolId: null,
      labelData: [],
      allTagOptions: [],
      uploadFlag: false,
      uploadPriceFlag: false,
      uploadAction: process.env.VUE_APP_API_HOST + "/collection/label/file",
      btnDisable: true,
      accept: ".xls,.xlsx",
      limit: 1,
      filePath: "",
      // 记录列表参数
      backParams: null,
      dialogVisible: false,
      // 上传价格
      uploadPrice: false,
      isUploadSuccess: false,
      // 是否显示合作品牌信息
      isShowCoopInfo: true,
      mapOrder: {
        fansNum: "粉丝数",
        postCount: "周作品数量",
        postRead: "单条阅读UV",
        postEngagement: "单条互动",
        waterIndex: "作品水量指数",
        taRatio: "TA浓度%",
        taTgi: "TA TGI",
        cpuv: "CPUV",
        cpe: "CPE",
        cpta: "CPTA",
        score: "Seeding Score",
        // waterAlert: "作品高水量Alert",
        coopCount: "合作次数",
        darkHorseIndex: "黑马指数",
        "sa.coopCount": "近3个月竞品合作次数",
      },
      filterId: "",
      // 记录次数
      num: 0,
      isSelectSite: false,
      // 自定义筛选tier
      myTier: ["All"],
      // mapCategory: [],
      // delIds
      delIds: [],
      // delDialog
      delDialog: false,
    };
  },
  computed: {
    ...mapState(["dialogClose", "duration", "filterInfo"]),
    ...mapGetters([
      "month",
      "siteValue",
      "amateurSiteValue",
      "categorySeg",
      "orderRule",
      "listVersion",
      "colSiteValue",
      "colCategorySeg",
      "colOrderRule",
      "token",
      "menuList",
    ]),
    setEmpty() {
      return "暂无数据";
    },
    // ...mapGetters(["menuList"]),
    seg() {
      return this.categorySeg;
    },
    platform() {
      return this.siteValue;
    },
    keyword() {
      return this.query.trim().replace(/\s+/g, " ");
    },
    filterInfoList() {
      return JSON.parse(this.filterInfo);
    },
    params() {
      let tempObj = {
        platform: this.platform, // 平台
        pageNo: this.listCurrentPage,
        kolName: this.keyword, // 搜索
        monthlyVer: this.month, // 月份,
        pageSize: this.listPageSize,
        sortBy: this.sortColumn,
        isAsc: this.sort !== 0
      };
      if(this.tags.filter !== {}){
        tempObj = {
          ...tempObj,
          ...this.tags.filter
        }
      }
      return tempObj;
    },
    // 将所有的filter以tag的形式展现
    filterTags() {
      let tempArr = [];
      // 分类
      if (
          this.tags.categories &&
          this.tags.categories.length !== 0 &&
          this.tags.categories[0] !== "All"
      ) {
        tempArr.push({
          name: `分类： ${this.tags.categories}`,
          type: "success",
        });
      }
      // 级别
      if (
          this.listVersion === 1 &&
          this.tags.tiers &&
          this.tags.tiers.length !== 0 &&
          this.tags.tiers[0] !== "All"
      ) {
        tempArr.push({
          name: `级别： ${this.tags.tiers}`,
          type: "warning",
        });
      }
      // 标签
      if (
          this.listVersion === 1 &&
          this.tags.labels &&
          this.tags.labels[0] !== "All" &&
          this.tags.labels.length !== 0
      ) {
        tempArr.push({
          name: `标签： ${this.tags.labels}`,
          type: "info",
        });
      }
      // if (
      //   this.listVersion === 1 &&
      //   this.tags.waterAlert &&
      //   this.tags.waterAlert[0] !== "All" &&
      //   this.tags.waterAlert.length !== 0
      // ) {
      //   tempArr.push({
      //     name: `作品高水量Alert： ${this.tags.waterAlert}`,
      //     type: "light",
      //   });
      // }
      // 其他筛选 数据筛选
      tempArr = [
        ...tempArr,
        ...this.filterTagArr.map(({ label, filterKeys }) => ({
          name: label,
          type: "",
          filterKeys,
        })),
      ];
      return tempArr;
    },
  },
  props: {
    showSideBar: {
      type: Boolean,
      default: true,
    },
    showListVersion: {
      type: Boolean,
      default: true,
    },
    showHandle: {
      type: Boolean,
      default: false,
    },
    collectionId: {
      default: 0,
    },
    size: {
      type: String,
      default: "normal",
    },
    colEmpty: {
      type: Boolean,
      default: false,
    },
  },
  beforeRouteLeave(to, from, next) {
    this.setIsAddClass(false);
    this.setListVersion(1);
    next();
  },
  async mounted() {
    this.num = 0;
    // this.tags.tiers = this.filterInfoList.tiers;
    // this.tags.tierCodeList = this.filterInfoList.tierCodeList;
    // this.tags.labels = this.filterInfoList.labels;
    // this.filterId = this.filterInfoList.filterId;
    // this.myTier = this.tags.tiers;
    // this.myTag = {
    //   tiers: this.filterInfoList.tiers,
    //   categories: this.filterInfoList.categories,
    //   filter: this.filterInfoList.filter,
    //   labels: this.filterInfoList.labels,
    // };
    // this.sortColumn = "fansNum";
    await this.getInfo()
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    ...mapMutations({
      setListVersion: "SET_LISTVERSION",
      setSites: "SET_SITES",
      setAmateursites:"SET_AMATEURSITES",
      setSiteValue: "SET_SITEVALUE",
      setColSiteValue: "SET_COLSITEVALUE",
      setIsHighLight: "SET_ISHIGHLIGHT",
      setListSortLabel: "SET_LISTSORTLABEL",
      setDetailsToRankList: "SET_DETAILSTORANKLIST",
      setIsAddClass: "SET_ISADDCLASS",
      setDialogClose: "SET_DIALOGCLOSE",
      setIsDisabled: "SET_ISDISABLED",
      setFilterinfo: "SET_FILTERINFO",
      // setDuration: "SET_DURATION",
    }),
    //获取社媒信息
    async getInfo(){
      const res =  await getInfo();
      const {  platformCategoryVOList } = res.data;
      // this.setDuration(duration);
      // vuex记录菜单
      // this.getUserInfo({
      //   month: { month },
      //   platformCategoryVOList,
      // });
      let tempArr = [];
      for (let item of platformCategoryVOList) {
        tempArr.push({ label: item.label, value: item.value });
      }
      this.setAmateursites(tempArr);
      this.setSiteValue(tempArr[0].value);
      this.setColSiteValue(tempArr[0].value);
    },
    // 重置条件
    resetFlagFunc() {
      this.resetFlag = true;
      this.sort = 1;
      this.sortColumn = "fansNum";
      this.$refs.normalTable.sortClick("fansNum", '总粉丝量', true);
      this.setListSortLabel("总粉丝量降序");
    },

    isShowUpload(val) {
      this.uploadPrice = false;
    },
    async submissionUploadPrice(param) {
      let { code } = await submitPriceUpload({
        fileName: param,
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "上传成功",
        });
        this.isUploadSuccess = true;
        setTimeout(() => {
          this.isUploadSuccess = false;
        }, 1000);
      } else {
        this.$message.error("上传失败,请联系管理员");
      }
    },
    downloadTemplate() {
      downloadPriceTemplate(
          {
            collectionId: this.collectionId,
          },
          "KOL价格模版.xlsx"
      );
    },
    fileDownload() {
      downloadKolLabelTemplate(
          {
            collectionId: this.collectionId,
          },
          "KOL标签模版.xlsx"
      );
    },
    uploadLabelFile() {
      this.uploadFlag = true;
    },
    delAllFunc() {
      if (!this.checkboxValue.length) {
        // 至少选择一个
        this.errorDialogVisible = true;
        this.errorDialogText = "请选择KOL！";
        return;
      } else {
        this.delDialog = true;
      }
    },
    fileBeforeUpload(file) {
      const sizeLimit = 1024 * 1024 * 100;
      if (file.size > sizeLimit) {
        this.$message({
          message: "文件不能大于100M",
          type: "warning",
        });
        return false;
      } else {
        return true;
      }
    },
    fileOnSuccess(res) {
      // 上传文件成功，取消禁用按钮
      if (res.code === 9004) {
        this.$message({
          type: "error",
          message: "文件解析失败,请查看模版文件!",
        });
        this.$refs.upload.clearFiles();
      } else if (res.code === 9999) {
        this.$message.error("上传失败，请联系管理员！");
      } else if (res.code === 0) {
        this.filePath = res.data;
        this.btnDisable = false;
      }
    },
    fileOnError() {
      this.$message.error("上传文件失败!");
    },
    fileRemove() {
      this.btnDisable = true;
    },
    async addFileSubmit() {
      let { code } = await submitFileUpload({
        fileName: this.filePath.replace(/\\/g, "/").split("/").pop(),
      });
      if (code === 0) {
        this.$message({
          type: "success",
          message: "上传成功",
        });
      } else {
        this.$message.error("上传失败,请联系管理员");
      }
      setTimeout(() => {
        this.uploadFlag = false;
        this.$refs.upload.clearFiles();
      }, 1000);
    },
    cancelAddFile() {
      this.$refs.upload.clearFiles();
      this.uploadFlag = false;
    },
    async getKolLabel() {
      let { code, data } = await getKolLabel();
      if (code === 0 && data.length) {
        // 去重
        data = Array.from(new Set(data));
        this.labelData = data.map((name) => ({
          name,
          tip: "",
        }));
        // kol展开后的tagOptions
        this.allTagOptions = data.map((v) => ({
          label: v,
          value: v,
        }));
      } else {
        this.labelData = [];
        this.allTagOptions = [];
      }
    },
    async getAmount() {
      const { code, data } = await getAmount();
      if (code === 0) {
        this.colTotal = data.total;
        this.collected = data.collected;
      }
    },
    async initData() {
      this.dataLoading = true;
      this.setIsDisabled(true);
      let res = null;
      res = await getkolAverageDataList(this.params);
      if (res?.code === 0 && res.data.table) {
        let tableData = res.data.table;
        if (JSON.stringify(tableData) === "[]") {
          this.indexNodata = true;
          this.rankListTableData = [];
          this.listTotalSize = 0;
        } else {
          this.indexNodata = false;
          // 有勾选的kol
          if (this.checkedKolList.length !== 0) {
            // 将勾选的kol插入头部
            tableData.unshift(...this.checkedKolList);
            tableData = this.removeDuplicates(tableData);
          } else {
            this.checkedKolList = [];
          }
          this.listTotalSize = res.data.total;
          this.rankListTableData = tableData;
        }
      } else {
        if (!res) return;
        this.indexNodata = true;
        this.rankListTableData = [];
        this.listTotalSize = 0;
      }
      setTimeout(() => {
        this.dataLoading = false;
        this.setIsDisabled(false);
      }, 50);
    },

    // 数组中去除重复对象
    removeDuplicates(array) {
      let seen = new Set();
      let uniqueArray = array.filter((obj) => {
        if (!seen.has(obj.mkolid)) {
          seen.add(obj.mkolid);
          return true;
        }
        return false;
      });
      return uniqueArray;
    },

    // 搜索和其他筛选, 切换平台也会触发一下
    // 有data表示是filter
    // 没有data表示是搜索
    newSearch(data) {
      if (
          data &&
          !data.tiers.includes("All") &&
          data.tiers.length === 1 &&
          data.tiers[0] !== this.myTier[0]
      ) {
        this.filterId = "";
      }
      if (this.$route.path === "/login") {
        return false;
      }
      if (data) {
        this.tags = data;
        if (
            this.tags.tiers.includes("All") &&
            !this.filterInfoList.tiers.includes("All") &&
            (this.num === 0 || this.num === 1)
        ) {
          if (this.isSelectSite) {
            let sort = this.filterInfoList.sortDirection === "asc" ? 1 : 0;
            if (this.myTier) {
              this.tags.tiers = this.myTier;
            }
            this.$refs.normalTable.sortClick(
                this.filterInfoList.sortColumn,
                sort,
                true
            );
          }
        }
      }
      this.listCurrentPage = 1;
      this.setListSortLabel("总粉丝量降序");
      this.initData();
    },
    // 自定义筛选触发
    // 页数选项变为 50/页
    // 设置 myTier 同步 filterTag里面的tier
    changeFilter(filterInfo, filterId = "") {
      if (filterInfo === "clear") {
        this.filterId = "";
        this.tags.tiers = ["All"];
        this.sort = 1;
        this.sortColumn = "fansNum";
        this.$refs.normalTable.sortClick("fansNum", '总粉丝量', true);
        this.setListSortLabel("总粉丝量降序");
      } else {
        this.isSelectSite = false;
        this.sort = filterInfo.sortDirection === "asc" ? 1 : 0;
        this.tags.tiers = filterInfo.tiers;
        this.tags.tierCodeList = filterInfo.tierCodeList;
        this.myTier = this.tags.tiers;
        this.sortColumn = filterInfo.sortColumn;
        this.filterId = filterId;
        this.listPageSize = 50;
        this.$refs.normalTable.sortClick(
            filterInfo.sortColumn,
            this.mapOrder[filterInfo.sortColumn],
            true
        );
      }
    },
    checkEmpty(flag) {
      this.isDataEmpty = flag;
    },
    sortByActiveViewerNumDesc(array) {
      return array.sort((a, b) => b.activeViewerNum - a.activeViewerNum);
    },
    // 新的赋值checkboxValue方法
    // value, kolList, bool
    checkboxValueChange(kolList, bool) {
      const newKolList = this.sortByActiveViewerNumDesc(kolList);
      // 指标2.0特殊格式
      const kolIndexArr = [];
      this.checkboxValue = [];
      newKolList.forEach((v) => {
        kolIndexArr.push(`${v.mKolid}|${v.activeViewerNum}`);
        this.checkboxValue.push(v.mkolid);
      });
      this.kolIndexs = kolIndexArr.join();
      this.checkedKolList = newKolList;
    },
    sortClick(col, label='总粉丝量', flag,sort) {
      if(sort){
        this.sort = Number(sort)
      }
      if (!flag) {
        if (col === this.sortColumn) {
          this.sort = 1 - this.sort;
        } else {
          this.sort = 0;
        }
      }

      if (col !== this.sortColumn) {
        this.filterId = "";
      }
      this.sortColumn = col;
      this.listCurrentPage = 1;
      this.setListSortLabel(this.sort === 0 ? `${label}降序` : `${label}升序`);
      // 发送排序的请求
      // this.filterId = "";
      this.initData();
    },
    openDetail(mKolId, name) {
      this.mKolId = mKolId;
      this.$storage.setItem("kolName", name);
      this.$storage.setItem("returnPath", this.$route.fullPath);
      this.dialogVisible = true;
      this.setIsAddClass(true);
    },
    reRenderTagShow() {
      // 重新获取label，更新 tagShow 组件
      this.getKolLabel();
    },
    handleCurrentChange(val) {
      this.listCurrentPage = val;
      this.initData();
    },
    handleSizeChange(val) {
      this.listPageSize = val;
      this.initData();
    },
    // 没找到数据点击提交
    intoKolCollection() {
      this.$router.push({ name: "KolCollection" });
    },
    async touchAnalysisDialogOpen() {
      this.loading = true;
      // checkedKolList
      this.touchAnalysisData = {};
      let params = {
        seg: "32_1",
        month: this.month,
        kolIds: this.checkboxValue,
        site: this.platform,
      };
      let params2 = {
        seg: "32_1",
        month: this.month,
        kolIds: this.checkboxValue.join(","),
        site: this.platform,
        duration: this.duration,
        kolIndexs: this.kolIndexs,
      };
      let res = null;
      if (this.listVersion === 1) {
        res = await kolTouchAnalysis(params2);
      } else {
        res = await touchAnalysisInitData(params);
      }
      if (res.code === 0) {
        if (JSON.stringify(res.data) === "{}") {
          this.noData = true;
        } else {
          this.noData = false;
          this.touchAnalysisData = res.data;
        }
      } else {
        this.noData = false;
        if (res.code === 123) this.$message.error("请联系管理员!");
      }
      this.loading = false;
    },
    imgClick(param) {
      if (param === "touchAnalysisDialogVisible") {
        if (this.isDataEmpty) {
          this.$message({
            message: '存在 "月UV" 为0的KOL，分析结果无意义，请重新选择',
            type: "info",
          });
          return false;
        } else if (!this.checkboxValue.length) {
          // 至少选择一个
          this.errorDialogVisible = true;
          this.errorDialogText = "至少选择一个KOL";
        } else {
          this[param] = true;
        }
      } else if (param === "uploadPrice") {
        this[param] = true;
      } else if (param === "downloadTier") {
        this.downloadTierFunc();
      } else if (!this.checkboxValue.length) {
        // 至少选择一个
        this.errorDialogVisible = true;
        this.errorDialogText = "至少选择一个KOL";
      } else {
        this[param] = true;
      }
    },
    async downloadTierFunc() {
      this.$message({
        type: "info",
        message: "数据正在解析，请耐心等待！",
      });
      let res = await downloadKolMedian("", "各Tier指标中位数.xlsx");
      if (res.code !== 123) {
        this.$message({
          type: "success",
          message: "文件正在下载！",
        });
      }
    },
    async openAddKol() {
      this.loading = true;
      let params = {
        searchWord: "",
        sortBy: "create_time",
        isAsc: 0,
        pageNo: this.taskCurrentPage,
        pageSize: this.taskPageSize,
      };
      let {
        code,
        data: { list, total },
      } = await getUserCollect(params);
      if (code === 0) {
        this.addToColData = list;
        this.addToColData.forEach((v) => {
          v.kolCount = Object.values(v.kolNum).reduce(
              (total, curr) => total + curr
          );
        });
        this.taskTotal = total;
      }
      this.loading = false;
    },
    closeAddKol() {
      this.taskCurrentPage = 1;
      this.addDisAble = true;
    },
    creatCollection() {
      this.innerVisible = true;
    },
    create() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let { code } = await creatCollect(this.form);
          if (code === 0) {
            this.$message({
              message: "创建收藏夹成功！",
              type: "success",
            });
            this.loading = true;
            this.innerVisible = false;
            this.addDisAble = true;
            await this.openAddKol();
            this.form.name = "";
            this.form.desc = "";
          } else {
            this.$message.error("创建失败!");
          }
        } else {
          return false;
        }
      });
    },
    resetForm() {
      nextTick(() => {
        this.$refs.ruleForm.resetFields();
      });
    },
    handleCurrentRow(currentRow) {
      if (currentRow === null) {
        return false;
      }
      nextTick(() => {
        setTimeout(() => {
          this.currentRowId = currentRow.id;
        }, 100);
      });
    },
    handleTaskCurrentChange(val) {
      this.taskCurrentPage = val;
      this.openAddKol();
    },
    async addConfirm() {
      if (this.checkboxValue.length === 0) {
        this.$message.info("请选择需要添加的KOL");
      } else if (this.currentRowId !== 0) {
        let addKolParams = {
          collectionId: this.currentRowId,
          kols: this.checkboxValue,
          nonKols: [],
        };
        let { code } = await submitKol(addKolParams);
        if (code === 0) {
          this.$message.success("添加KOL成功!");
          this.addCancel();
        } else {
          this.$message.error("请求失败,请联系管理员");
          this.addCancel();
        }
      }
    },
    addCancel() {
      this.addToCollection = false;
      this.addDisAble = true;
      this.currentRowId = 0;
    },
    async compareDialogOpen() {
      this.loading = true;
      let params = {
        duration: this.duration,
        month: this.month,
        kolIds: this.checkboxValue.join(","),
      };
      let { code, data } = await personalAnalysis(params);
      if (code === 0) {
        if (JSON.stringify(data) === "{}") {
          this.noData = true;
          return;
        }
        for (let item of Object.values(data)) {
          if (JSON.stringify(item) === "{}") {
            this.noData = true;
            return;
          }
        }
        this.noData = false;
        nextTick(() => {
          this.compareData = data;
        });
      } else {
        this.noData = true;
      }
      this.loading = false;
    },
    async getBenchmark() {
      const { code, data } = await getBenchmark({
        platform: this.platform,
        duration: this.duration,
      });
      if (code === 0) {
        this.benchmarkValue =
            data === null
                ? {
                  contentQualityIndex: null,
                  effectiveTrafficIndex: null,
                  fansActiveIndex: null,
                  waterIndex: null,
                }
                : data;
      }
    },
    tagClose(tag) {
      // 分类
      if (tag.type === "success") {
        this.$refs.filterTag.delCategoryData();
      }
      // 级别
      else if (tag.type === "warning") {
        this.filterId = "";
        this.$refs.filterTag.delTierData();
      }
      // 标签
      else if (tag.type === "info") {
        this.$refs.filterTag.delLabelData();
      }
      // 合作信息删除
      else if (tag.type === "light") {
        this.$refs.filterTag.delLightTypes();
      }
          // 其他筛选 数据筛选
      // 需一一删除
      else {
        const i = this.filterTagArr.findIndex(
            ({ label }) => label === tag.name
        );
        // 删除tah展示
        this.filterTagArr.splice(i, 1);
        // 删除提交数据
        tag.filterKeys.forEach((k) => delete this.tags.filter[k]);
        // 删除filter组件内部数据以便下次提交保持一致
        this.$refs.filterTag.deleteFilterData(tag.filterKeys);
        this.initData();
      }
    },
    async handleListType() {
      this.tags = {
        categories: [], // 标签
        tiers: [], // tier，榜单1.0没有tier
        labels: [],
        filter: {}, // 其他筛选,
      };
      // 其他筛选 数据筛选的一维数组集合
      this.filterTagArr = [];
      this.tagshow = false;
      this.query = "";
      this.resetFlag = false;
      this.checkedKolList = [];
      this.checkboxValue = [];
      this.$refs.normalTable.checkBoxList = [];
      this.listCurrentPage = 1;
      this.setIsHighLight(true);
      if (this.listVersion === 1) {
        this.sortColumn = "activeViewerNum";
        await this.getBenchmark();
      } else {
        switch (this.platform) {
          case "wechat":
            this.sortColumn = "muv";
            break;
          case "weibo":
            this.sortColumn = "mact";
            break;
          case "douyin":
            this.sortColumn = "muv_fans";
            break;
          case "xiaohongshu":
            this.sortColumn = "muv";
            break;
          case "bilibili":
            this.sortColumn = "muv";
            break;
          case "kuaishou":
            this.sortColumn = "muv";
            break;
        }
      }
      await this.getAmount();
    },
    // 删除kol，针对常用达人
    openDelKolDialog(id) {
      this.delKolDialogVisible = true;
      this.delKolId = id;
    },
    async realDel() {
      this.delKolDialogVisible = false;
      let { code } = await delHaveKol({ id: this.delKolId });
      if (code === 0) {
        let colIndex = this.rankListTableData.findIndex((v) => {
          return v.id === this.delKolId;
        });
        this.rankListTableData.splice(colIndex, 1);
        setTimeout(() => {
          this.$message({
            message: "删除KOL成功!",
            type: "info",
          });
        }, 200);
      }
      this.checkedKolList = [];
      await this.getAmount();
      await this.getCollectionInfo();
      await this.initData();
    },
    async realDelAll() {
      this.delDialog = false;
      let res = await delAllKol(this.delIds);
      if (res.code === 0) {
        this.delIds.forEach((k) => {
          let colIndex = this.rankListTableData.findIndex((v) => {
            return v.id === k;
          });
          this.rankListTableData.splice(colIndex, 1);
        });

        setTimeout(() => {
          this.$message({
            message: "删除KOL成功!",
            type: "info",
          });
        }, 200);
      }
      this.checkedKolList = [];
      await this.getAmount();
      await this.getCollectionInfo();
      await this.initData();
    },
    async getCollectionInfo() {
      let { data } = await getCollectionInfo({
        collectionId: this.collectionId,
      });
      let kolNumber = {
        kolAmount: data.kolAmount,
        nonKolAmount: data.nonKolAmount,
      };
      this.$emit("refreshKolNumber", kolNumber);
    },
    // 追加kol
    addToActivity() {
      if (!this.checkedKolList.length) {
        this.$message({
          message: "请选择KOL!",
          type: "warning",
        });
      } else {
        this.$emit("addToActivity", this.checkedKolList);
      }
    },
  },
  watch: {
    menuList(val) {
      if (val) {
        let tempArr = val.find((v) => v.id === 410);
        if (tempArr) {
          let isShowCoopInfo = tempArr.children.find((v) => v.id === 430);
          if (isShowCoopInfo) {
            this.isShowCoopInfo = true;
          } else {
            this.isShowCoopInfo = false;
          }
        }
      }
    },
    seg(val) {
      if (val || val === null) {
        this.checkedKolList = [];
        this.checkboxValue = [];
        this.$refs.normalTable.checkBoxList = [];
        this.listCurrentPage = 1;
        this.initData();
      }
    },
    platform(val) {
      this.setFilterinfo(
          `{"categories":[],"coopBrand":"","filter":{},"filterId":"","kolNames":"","labels":[],"page":1,"platform":"${val}","seg":"32_1","size":20,"sortColumn":"activeViewerNum","sortDirection":"asc","tiers":["All"]}`
      );
      this.num = 0;
      this.isSelectSite = true;
      this.checkedKolList = [];
      this.checkboxValue = [];

      this.$refs.normalTable.checkBoxList = [];
      this.$refs.filterTag.resetClick()
      // 排序列
      // switch (newVal) {
      //   case "wechat":
      //     this.sortColumn = "muv";
      //     break;
      //   case "weibo":
      //     this.sortColumn = "mact";
      //     break;
      //   case "douyin":
      //     this.sortColumn = "muv_fans";
      //     break;
      //   case "xiaohongshu":
      //     this.sortColumn = "muv";
      //     break;
      //   case "bilibili":
      //     this.sortColumn = "muv";
      //     break;
      //   case "kuaishou":
      //     this.sortColumn = "muv";
      //     break;
      // }
      if (this.listVersion === 1) {
        // this.sortColumn = "mact";
        // this.getBenchmark();
      }
      // this.sort = 0;
    },
    currentRowId(val) {
      if (val !== 0) {
        this.addDisAble = false;
      }
    },
    orderRule(newVal) {
      let arr = newVal.split("_");
      this.sort = +arr[1];
      this.$refs.normalTable.sortClick(arr[0], this.mapOrder[arr[0]], true);
    },
    colOrderRule(newVal) {
      let arr = newVal.split("_");
      // this.sortColumn = arr[0];
      this.sort = +arr[1];
      this.$refs.normalTable.sortClick(arr[0], this.mapOrder[arr[0]], true);
    },

    listVersion() {
      this.handleListType();
    },
    collectionId() {
      // 常用达人收藏夹变化
      this.checkedKolList = [];
      this.checkboxValue = [];
      this.$refs.normalTable.checkBoxList = [];
      this.listCurrentPage = 1;
      this.initData();
    },
    dialogClose(val) {
      if (val) {
        this.dialogVisible = false;
        this.setIsAddClass(false);
        this.setDialogClose(false);
      }
    },
  },
  components: {
    Sidebar,
    filterCom,
    toolBar,
    newSelectCom,
    filterTag,
    touchAnalysis,
    tableCom,
    errorDialog,
    tableComponent,
    kolDetails,
    headerCom,
    uploadFile,
  },
};
</script>
<style lang="scss">
.container {
  .upload_kolTag {
    text-align: center;
    position: relative;

    .el-upload-list.el-upload-list--text {
      position: absolute;
      top: 54%;
      left: 45%;
      transform: translateX(-50%);

      .el-upload-list__item-name {
        max-width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
      }
    }

    .downloadBtn {
      margin-top: 10px;
      cursor: pointer;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;

      &:hover {
        color: #409eff;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
      }
    }
  }

  .el-dialog.is-fullscreen .el-dialog__header {
    padding: 0;
  }

  .el-dialog.is-fullscreen .el-dialog__body {
    padding: 0;
    height: 100%;
  }
}
</style>

<style scoped lang="scss">
.resetBtn {
  color: $color-text-l;

  .text {
    color: #2e6ea5;
    font-weight: bold;
    display: inline-block;
    vertical-align: baseline;
  }
}

.container {
  width: 100%;
  height: 100%;
  display: flex;

  .upLoadInfo {
    margin-right: 0;

    &:hover svg {
      fill: #66b1ff;
    }
  }

  .delAll {
    margin-right: 20px;
  }

  .uploadStyle {
    font-size: 17px;
  }

  .delAllStyle {
    fill: #777;
    font-size: 20px;
    margin-right: 3px;
  }

  .tagDiv {
    margin-top: 10px;

    .el-tag {
      margin-right: 10px;
    }
  }

  .tableNoData {
    position: relative;
    top: -40px;
    z-index: 100;
    background-color: #fff;
  }

  .dataDiv {
    flex: 1;
    margin-top: 5px;
    overflow: hidden;

    .tipP {
      margin-top: 5px;
    }

    .pagination {
      text-align: center;
      margin-top: 10px;

      .dataTime {
        float: left;
        font-size: $font-size-medium;
        line-height: 30px;
        color: $color-sub-title;
      }
    }

    .el-checkbox-group {
      min-height: 100%;
      overflow: auto;

      .el-checkbox {
        display: block;
        line-height: 34px;
        margin: 0;
      }
    }
  }

  .articlePaginationBox {
    text-align: center;
    margin: 20px 0;
    position: relative;
  }

  .amount {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .createBtn {
    position: absolute;
    right: 19px;
    top: 31px;
    font-size: 14px;
  }

  .el-header {
    background-color: transparent;
    color: $color-text;
  }

  .el-main {
    color: $color-text;
    width: 100%;
    margin: 0 auto;
  }

  .el-footer {
    background-color: $color-dialog-background;
    color: $color-text-l;
    line-height: 30px;
    text-align: center;
    border-top: 1px solid #ddd;
  }
}
.pagination{
  display: flex;
  justify-content: space-between;
}
.dialog_container{
  height: 100%;
  background:#EFF3F4 url("../../assets/images/Background.png") no-repeat top;
  background-size:100% 400px;
}
</style>
