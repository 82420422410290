<template>
  <div class="filter">
    <!--目标人群-->
    <div/>
    <div class="btnGroup">
      <!--      <el-icon class="mr-5 color888" v-if="!isExpand"><Plus /></el-icon>-->
      <!--      <el-icon class="mr-5 color888" v-else><Minus /></el-icon>-->
      <!--      <el-button type="text" class="noborderBtn" @click="expandAll">-->
      <!--        {{ isExpand ? t("rankingList.allPackUp") : t("rankingList.allExpand") }}-->
      <!--      </el-button>-->
      <el-select
          class="autoWidth"
          v-model="order"
          @change="handleOrder"
          placeholder="请选择排序方式"
      >
        <template #prefix v-if="order !== ''">
          {{ order }}
        </template>
        <el-option
            v-for="(item, i) in orderOptions"
            :key="item.value"
            :class="
            i % 2 !== 0 ? (i === orderOptions.length - 1 ? '' : 'myOption') : ''
          "
            :label="item.label+(item.value.split('_')[1] === '0' ? '降序' : '升序')"
            :value="item.value"
        >
        </el-option>
      </el-select>

      <!-- <el-button
        v-show="isSubmitKbsShow"
        style="margin-right: 20px"
        @click="submitKBS"
        type="primary"
        size="mini"
        >提交到KBS
      </el-button> -->
    </div>
  </div>
</template>

<script>
import crowedCom from "./crowedCom/crowedCom";
import { mapGetters, mapMutations } from "vuex";
import { downloadCrowd, downloadCrowdV2 } from "@/api/collection";
// import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";
import { Plus, Minus } from "@element-plus/icons-vue";
// import listVersion from "@/components/common/listVersion";

export default defineComponent({
  setup() {
    // const { t } = useI18n();
    // return { t };
  },
  name: "filterCom",
  data() {
    return {
      downLoading: false,
      order: "",
      // isCommitKBSdesabled: false,
    };
  },
  props: {
    colEmpty: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: String,
      default: "",
    },
    showListVersion: {
      type: Boolean,
      default: true,
    },
    collectionId: {
      default: 0,
    },
  },
  computed: {
    ...mapGetters([
      "isExpand",
      "siteValue",
      "month",
      "colTargetCrowd",
      "colSiteValue",
      "colCategorySeg",
      "listVersion",
      "listSortLabel",
      "orderRule",
    ]),
    orderOptions() {
      return [
        {
          label: "近4周新增作品数量",
          value: "postCountGrowthTrend_1",
        },
        {
          label: "近4周新增作品数量",
          value: "postCountGrowthTrend_0",
        },
        {
          label: "总粉丝量",
          value: "fansNum_1",
        },
        {
          label: "总粉丝量",
          value: "fansNum_0",
        },
        {
          label: "近4周总粉丝量增长率",
          value: "fansGrowthTrend_1",
        },
        {
          label: "近4周总粉丝量增长率",
          value: "fansGrowthTrend_0",
        },
        {
          label: "总互动量",
          value: "engagementSum_1",
        },
        {
          label: "总互动量",
          value: "engagementSum_0",
        },

        {
          label: "近4周总互动量增长率",
          value: "engagementGrowthTrend_1",
        },
        {
          label: "近4周总互动量增长率",
          value: "engagementGrowthTrend_0",
        }

      ];
    },
    // 追加kol
    downloadCrowdFlag() {
      return this.$route.name !== "RankingListV2";
    },
    isSubmitKbsShow() {
      return this.$route.name !== "RankingListV2";
    },
    addActivity() {
      return (
          this.$route.name !== "RankingListV2" &&
          this.$route.name !== "CollectDetail"
      );
    },
    site() {
      return this.$route.name === "RankingListV2"
          ? this.siteValue
          : this.colSiteValue;
    },
  },
  mounted() {
    this.setIsExpand(false);
    this.judgeOrder();
  },
  methods: {
    ...mapMutations({
      setIsExpand: "SET_ISEXPAND",
      setOrderRule: "SET_ORDERRULE",
      setColOrderRule: "SET_COLORDERRULE",
      setIsHighLight: "SET_ISHIGHLIGHT",
      setIsSubmit: "SET_ISSUBMIT",
    }),
    handleOrder(val) {
      this.setIsHighLight(false);
      let value = val.split("_")[0]
      let label = this.orderOptions.find(item => item.value === val).label
      let sort = val.split("_")[1]
      this.$emit("changeSort", value, label, true,sort);
    },
    expandAll(event) {
      this.setIsExpand(!this.isExpand);
      event.target.parentNode.blur();
    },
    // 常用达人下载人群按钮
    downloadCrowd() {
      if (this.colEmpty) {
        this.$message({
          message: "还未添加KOL，无数据",
          type: "info",
        });
        return;
      }
      this.downLoading = true;
      this.$message({
        message: "数据正在导出，请不要刷新切换页面，耐心等待!",
        type: "info",
      });
      let params = {
        collectionId: this.collectionId,
        month: this.month.month,
        seg: this.colCategorySeg,
      };
      const fileName = `${this.colTargetCrowd.name}_前测数据.xlsx`;
      if (this.listVersion) {
        params.duration = this.duration;
        downloadCrowdV2(params, fileName).then(() => {
          this.$message({
            message: "正在导出",
            type: "success",
          });
          this.downLoading = false;
        });
      } else {
        downloadCrowd(params, fileName).then(() => {
          this.$message({
            message: "正在导出",
            type: "success",
          });
          this.downLoading = false;
        });
      }
    },
    // submitKBS() {
    //   this.setIsSubmit(true);
    // },
    // 创建活动：加入侧边栏
    addToActivity() {
      this.$emit("addToActivity");
    },
    judgeOrder() {
      this.order = this.listSortLabel;
    },
  },
  watch: {
    site() {
      this.judgeOrder();
    },
    listVersion() {
      this.judgeOrder();
    },
    listSortLabel(val) {
      this.order = val;
    },
  },
  components: {
    crowedCom,
    Plus,
    Minus,
    // listVersion,
  },
});
</script>

<style lang="scss">
.myOption {
  border-bottom: 1px solid #ccc;
}
</style>

<style scoped lang="scss">
.filter {
  height: 41px;
  line-height: 41px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btnGroup {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding:0 20px;
  }
}

.color888 {
  color: #888;
}
</style>
